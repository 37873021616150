<template>
  <div>
    <div
      :class="[
        'bottomBox',
        (isReceivedOrder || [-1, 0].includes(onlineDetail.status)) &&
          'lowHieghtBottom',
        isCompletedOrder &&
          ![-1, 0].includes(onlineDetail.status) &&
          'HieghtBottom',
        otherBtns.length > 0 && 'flowTop',
      ]"
    >
      <div v-if="isCompletedOrder || isReceivedOrder">
        <div
          class="driverbox"
          v-if="isCompletedOrder && ![-1, 0].includes(onlineDetail.status)"
        >
          <img src="../../../../assets/images/driverHead.png" />
          <span>{{ onlineDetail.extOrderDataDynamicData.driverCarType }}</span>
          <span>{{ onlineDetail.extOrderDataDynamicData.driverName }}</span>
          <span>{{ onlineDetail.extOrderDataDynamicData.driverCode }}</span>
          <span>{{ onlineDetail.extOrderDataDynamicData.driverColor }}</span>
        </div>
        <ul class="bottomBtnList">
          <div class="dropDownBox" v-if="isDrop">
            <template v-if="(!onlineDetail.extOrderDataDynamicData.driverMobile && processBtn.length > 4) || (onlineDetail.extOrderDataDynamicData.driverMobile && processBtn.length > 3)">
              <span
                v-for="(btn1, bindex1) in processBtn.slice(
                  onlineDetail.extOrderDataDynamicData.driverMobile?2:3,
                  processBtn.length
                )"
                :key="bindex1"
                @click.stop="isloding && btnClick(onlineDetail, btn1)"
                >{{ btn1.txt }}</span
              >
            </template>
          </div>
          <a
            class="moreBtn"
            v-if="(!onlineDetail.extOrderDataDynamicData.driverMobile && processBtn.length > 4) || (onlineDetail.extOrderDataDynamicData.driverMobile && processBtn.length > 3)"
            @click.stop="isDrop = !isDrop"
            >更多</a
          >
          <li
            v-if="onlineDetail.extOrderDataDynamicData.driverMobile"
            @click="
              ihtml.telCall(onlineDetail.extOrderDataDynamicData.driverMobile)
            "
          >
            <svg class="icon bottomIcon" aria-hidden="true">
              <use xlink:href="#icondianhua-" />
            </svg>
            <p>联系司机</p>
          </li>
          <li
            v-for="(btn, bindex) in processBtn.slice(0, onlineDetail.extOrderDataDynamicData.driverMobile && processBtn.length > 3? 2 : !onlineDetail.extOrderDataDynamicData.driverMobile && processBtn.length > 4 ? 3 : 4)"
            :key="bindex"
            :class="btn.processor"
            @click="btn.processor != 'localtionOrder' && isloding && btnClick(onlineDetail, btn) ||
              btn.processor == 'localtionOrder' && isloding && !onlineDetail.img_url && localtionOrder(false)
            "
          >
            <svg class="icon bottomIcon" aria-hidden="true">
              <use :xlink:href="'#' + btn.css" />
            </svg>
            <p>{{ btn.txt }}</p>
            <viewer v-if="(btn.processor == 'localtionOrder' && onlineDetail.img_url)" :images="[onlineDetail.img_url]" class="imgContainer">
              <img style="width:100%;height:100%" :src="item.url"  v-for="item in onlineDetail.img_url" alt :key="item.url"/>
            </viewer>
          </li>
        </ul>
      </div>
      <template v-else>
        <div class="timeBox" v-if="!isPlaceOrder && itemData">
          <svg class="icon timeIcon" aria-hidden="true">
            <use xlink:href="#icon-shijian" /></svg>{{
            itemData.carRule == 14
              ? "立即出发"
              : itemData.departure_time + "出发"
          }}
          <!-- <svg class="icon backIcon" aria-hidden="true">
          <use xlink:href="#iconarrow-right" />
        </svg> -->
        </div>
        <div class="btnBox">
          <div class="infoBox">
            <p>
              预估<i
                >{{ chosePricesInfo.minPrice || 0 }}~{{
                  chosePricesInfo.maxPrice || 0
                }}</i
              >元起
            </p>
            <span
              >共<i>{{ nuPriceArr.length || 0 }}</i
              >个车型</span
            >
          </div>
          <div
            :class="[
              'bottomBtn',
              (!isloding || priceArr.length == 0) && 'unclickSubmit',
            ]"
            @click.stop="isloding && saveItem(1)"
          >
            <svg v-if="!isloding" class="icon circleIcon" aria-hidden="true">
              <use xlink:href="#iconshuaxin" /></svg>{{ !isPlaceOrder ? "同时呼叫" : "追加叫车" }}
          </div>
        </div>
      </template>
    </div>
    <div class="otherBtnbox" v-if="otherBtns.length > 0">
      <div
        v-for="(obtn, oindex) in otherBtns"
        :key="oindex"
        @click="isloding && btnClick(onlineDetail, obtn)"
      >
        <h3>{{ obtn.title }}</h3>
        <p>{{ obtn.subTitle }}</p>
        <div class="otherTogo">
          {{ obtn.txt }}
          <svg class="icon otherBtnIcon" aria-hidden="true">
            <use xlink:href="#iconarrow-right" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
Viewer.setDefaults({
    title: false,
    navbar:true,
    button:true,
    toolbar:{
    zoomIn: 0,
    zoomOut: 0,
    reset: 0,
    rotateLeft: 0,
    rotateRight: 0,
    flipHorizontal: 0,
    flipVertical: 0,
    prev:1,
    next:1
  }
  })
export default {
  props: [
    "isReceivedOrder",
    "isPlaceOrder",
    "itemData",
    "isCompletedOrder",
    "isloding",
    "onlineDetail",
    "saveItem",
    'img_url'
  ],
  watch: {
    dropDown: {
      handler(newValue, oldValue) {
        this.isDrop = false;
      },
    },
  },
  data() {
    return {
      isDrop: false,
      chosePricesInfo: {
        minPrice: 0,
        typeNum: 0,
      },
      priceArr: [],
      nuPriceArr: [],
      processBtn: [],
      otherBtns: [],
    };
  },
  created() { 
      this.otherBtns=[];
      this.processBtn=[];
      this.onlineDetail?.itsButtons?.forEach((element) => {
        element.buttonType == 1
          ? this.otherBtns.push(element)
          : this.processBtn.push(element);
      });
    
    // })
  },
  beforeDestroy() {
    this.$root.$bus.$off("reloadPriceData");
    this.$root.$bus.$off("reloadBtn");
  },
  methods: {
    async btnClick(item, btn) {
      console.log('======',item,btn);
      this.clickItem = this.onlineDetail;
      if (["forceCancelOrder", "cancelOrder"].includes(btn.processor)) {
        this.$parent.cancelOrders(item);
      } else {
        await this.$parent[btn.processor](item, btn,true,true)
      }
    },
    localtionOrder(){
      this.$iToast("暂无轨迹，请稍后再试");
    }
    
  },
};
</script>
<style scoped="scoped" lang="scss">
.moreBtn {
  margin-top: 0.1rem;
  display: block;
  float: right;
  margin-right: 0.1rem;
}
.dropDownBox {
  position: absolute;
  width: 26%;
  background: #fff;
  top: -90%;
  box-shadow: -1px 4px 7px 1px #e2e2e2;
  border-radius: 4px;
  right: 0;
  z-index: 99;
  border: 1px solid #f5f5ff;

  span {
    border-bottom: 1px solid #f5f5ff;
    box-sizing: border-box;
    padding: 0.08rem 0.1rem;
    display: block;
    color: $sub_txt_color;
  }

  &:last-child {
    border: none;
  }
}

.dropDownBox:before,
.dropDownBox:after {
  // bottom: 100%;
  // left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  bottom: -16px;
    left: 50%;
    transform: rotateX(180deg);
}

.dropDownBox:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.dropDownBox:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #f5f5ff;
      border-width: 8px;
    margin-left: -8px;
}
.imgContainer{
  position: absolute;
  width: 100%;
  height: 60%;
  top: 20px;
  z-index: 1000;
  img{
    filter:opacity(0%)
  }
}

</style>
<style lang="scss">
//单独处理图片预览左右箭头
.viewer-toolbar{
  position: fixed!important;
    top: 45%!important;
    ul{
      width: 100%;
      position: fixed;
      .viewer-prev{
        left: 0!important;
    }
    .viewer-next::before{
      position: fixed;
      right: 0!important;
    }
    .viewer-next{
      position: fixed;
      right: 0!important;
    }
    }
    
}
</style>