<template>
  <div class="priceCpiponBox">
    <iHeader
      backType="blank"
      :headStyle="{
        background: 'var(--themeColor)',
        color: '#fff',
      }"
      :backEvent="true"
      @back-event="detailPageF ? detailPageF = false : priceCouponGoBack()"
      :back="true"
    >
    <template v-if="detailPageF" v-slot:headText >预估车费明细</template>
      <template v-slot:rightBtn v-if="isNoOrder && !detailPageF">
        <div @click="isloding && saveItem(false)">保存</div>
      </template>
    </iHeader>
    <div
      id="containerP"
      :class="[
        'maps',
        [0].includes(onlineDetail.status) && 'bigMap',
        isNoOrder && 'smaiMap',
      ]"
    ></div>
    <!-- <img
      v-if="lodingPrice"
      class="grtPriceLoading"
      src="../../assets/images/loading2.gif"
    /> -->
    <svg
      v-if="lodingPrice"
      class="icon carLodingIcon transition_dom"
      aria-hidden="true"
    >
      <use xlink:href="#iconhuiyinqing" />
    </svg>
    <div v-if="!Loading">
      <!-- <priceCoupon :saveItem="saveItem"
        :isloding="isloding" @chosePrices="chosePrices" :levelList="levelList.prices" :didiPrice="didiPrice" :itemData="params.ItemData"></priceCoupon> -->
      <div
        :class="[
          'priceCouponBox',
          'transition_dom',
          isNoOrder && 'hasBack',
          isPlaceOrder && 'placeOrderPriceCouponBox',
          isReceivedOrder && 'receivedPriceCouponBox',
          onlineDetail.extOrderStatus == 10 && 'noDriverOrderPriceCouponBox',
          isCompletedOrder && 'CompletedPriceCouponBox',
          ([-1, 0].includes(onlineDetail.status)&&!isPlaceOrder) && 'cancelPriceCouponBox',
          ([0].includes(onlineDetail.status)&&!isPlaceOrder) && 'unOrderPriceCouponBox',
          (hasOtherBtn && [10].includes(onlineDetail.extOrderStatus)) && 'hasOtherBtnPriceCouponBox',
          (hasOtherBtn && [30,40,50,60].includes(onlineDetail.extOrderStatus)) && 'hasOtherBtnPriceCouponBox2',
        ]"
      >
        <div class="pricescrollBox">
          <div class="topbox">
            <span></span>
          </div>
          <div class="fiexBox" v-if="!Loading && isNoOrder">
            <div class="levelbox">
              <template v-for="(type, tindex) in levelList.prices">
                <span
                  :class="type.typeSelected && 'choiseLevel'"
                  @click="goLevel(type)"
                  v-if="type.carPrice.length > 0"
                  :key="tindex"
                >
                  {{ type.carLevelTxt }}
                  <p>
                    {{ Math.ceil(type.minPrice) }}~{{
                      Math.ceil(type.maxPrice)
                    }}元
                  </p>
                </span>
              </template>
            </div>
          </div>

          <div
            class="priceCouponContent"
            ref="pricescrollBox"
          >
            <template v-if="!Loading && isNoOrder">
              <noOrder
                :detailPageF="detailPageF"
                :levelList="levelList.prices"
                :didiPriceList="didiPriceList"
                @detailPage="detailPage"
                @chosePrices="chosePrices"
                :isPlaceOrder="isPlaceOrder"
                :itemData="params.ItemData"
                :saveItem="saveItem"
                :params="params"
                :cancelOrders="cancelOrders"
                ref="noOrderRef"
              ></noOrder>
            </template>
            <template v-if="isCompletedOrder">
              <completedOrder
                :onlineDetail="onlineDetail"
                :hasOtherBtn="hasOtherBtn"
              ></completedOrder>
            </template>
          </div>
        </div>
        <div class="submit_box"  v-if="!Loading && isNoOrder">
          <div class="submit_time">
          <svg class="icon timeIcon" aria-hidden="true">
            <use xlink:href="#iconclock-five" /></svg>
              {{itemData.departure_time + " 出发"}}
          </div>
          <div v-if="this.SmartStorage.get('eventData').statusTxt == '会议执行'" class="submit_btn_box">
            <div class="submit_btn" @click="OnlineSubmitItem">
              提交订单
            </div>
          </div>
          <div v-else class="submit_btn_box">
            <div class="submit_btn" @click="saveItem(false)">
              保存订单
            </div>
          </div>
        </div>
        <bottomContent
          v-if="isCompletedOrder"
          :onlineDetail="onlineDetail"
          :itemData="params.ItemData"
          :isReceivedOrder="isReceivedOrder"
          :isCompletedOrder="isCompletedOrder"
          :isPlaceOrder="isPlaceOrder"
          :saveItem="saveItem"
          :isloding="isloding"
          :img_Url="img_Url"
        ></bottomContent>
      </div>
    </div>
    <div class="carLevelsOne carLoding" v-else>
      <Skeleton :skList="skList" />
      <img src="../../assets/images/carLoding.gif" />
    </div>
    <MessageBox
      ref="tipsMsg"
      type="tips"
      :btnType="btnType"
      singleTxt="确定"
      :messageTxt="messageTxt"
      @on-single="MessageBtn()"
      @on-ok="MessageBtn()"
    ></MessageBox>
    <cancelResonPop
      ref="cancelResonPop"
      :canselect="canselect"
      :cancelReson="cancelReson"
    ></cancelResonPop>
    <returnBox
      :retype="'carRemark'"
      @closeDialog="isCarRemarkDialog = false"
      @quotePiece="SaveUseCarRemark"
      :isloding="isloding"
      :showBtn="showBtn"
      :carRemark="onlineDetail.itsItemData.useCarRemark"
      v-if="isCarRemarkDialog"
    ></returnBox>
    <projectGrade ref="projectGrade" :refrashData="refrashData"></projectGrade>
    <browserLoader
      :iframeUrl="iframeUrl"
      v-if="showDiDiH5"
      :h5GoClose="h5GoClose"
    ></browserLoader>
    <Map ref="mapPop" @close="refrashData" />
  </div>
</template>
<script>
import Vue from "vue";
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview);
import browserLoader from "@/views/common/platform/browserLoader";
import projectGrade from "@/views/common/projectGrade/projectGrade";
import returnBox from "@/views/common/returnBox";
import axios from "axios";
import cancelResonPop from "@/views/common/platform/cancelResonPop";
import noOrder from "@/views/common/platform/priceCoupon/onlineNoOrder";
import submitOrder from "@/views/controllers/onlineSubmitOrder.js";
import mapSet from "@/views/controllers/mapSet.js";
import Map from "@/views/common/map/map";
import filterEmoji from "@/views/controllers/filterEmoji.js";
import completedOrder from "@/views/common/platform/priceCoupon/onlineCompletedOrder";
import bottomContent from "@/views/common/platform/priceCoupon/onlineBottomContent";
export default {
  mixins: [mapSet, submitOrder, filterEmoji],
  data() {
    return {
      ItemData: {},
      detailPageF: false,

      btnType:"double",
      iframeUrl: "",
      showDiDiH5: false,
      showBtn: true,
      isCarRemarkDialog: false,
      polyline: [],
      arratGps: [],
      cancelReson: [],
      canselect: true,
      reason: "",
      otherReason: "",

      onlineDetail: {},
      isDiDi: true,
      isNoOrder: true,
      isPlaceOrder: false,
      isReceivedOrder: false,
      isCompletedOrder: false,
      skList: [
        {
          width: "65%",
          row: 2,
        },
        {
          width: "20%",
          position: "right",
        },
      ],
      estimateDistance: 0,
      tokenFlage: true,
      messageType: "",
      messageTxt: "",
      isChoseType: true,
      choiseItemId: "",
      IsCheckRepeatOrder: 1,
      saveType: 1,
      isloding: true,
      lodingPrice: false,
      isDiDiqiye:false,
      params: {
        IsExtra: true,
        ItemData: {},
      },
      markerList: [],
      marker: {},
      Loading: true,
      levelList: {
        userPersonalizations: {},
        prices: [],
      },
      arrryLeves: [],
      priceArr: {},
      didiPrice: [],
      didiPriceList: {
        minPrice: 0,
        maxPrice: 0,
      },
      obj: { 经济型: [], 舒适型: [], 商务型: [] },
      arr: [],
      iindex: 0,
      che: [],
      maplineArra: [],
      hasOtherBtn: false,
      img_Url: null
    };
  },
  async mounted() {
    this.map = new AMap.Map("containerP", {
        resizeEnable: true,
        setZoomAndCenter: [
            this.params.ItemData.flng,
            this.params.ItemData.flat,
        ],
    });
    let data = this.SmartStorage.get("onlineData");
    this.params.ItemData = data
    let startLngLat = [this.params.ItemData.flng, this.params.ItemData.flat];
    let endLngLat = [this.params.ItemData.tlng, this.params.ItemData.tlat];
    let that = this
    this.planDrivingLine = new AMap.Driving({
        map: that.map,
        showTraffic: true, //实时路况信息
        hideMarkers: true,
        autoFitView: false,
        outlineColor: "#357950"
    })
    // 根据起终点经纬度规划驾车导航路线
    this.planDrivingLine.search(startLngLat, endLngLat, function (status, result) {
        if(result.routes[0] ){
          let distanceOk = (result.routes[0].distance * 0.001).toFixed(2);
          that.estimateDistance = distanceOk        }
    });
    if (!this.$route.query.type) {
      this.GetEstimatePrice()
      this.markerSet({ slng: data.flng, slat: data.flat }, { tlng: data.tlng, tlat: data.tlat })
      this.map.setFitView(this.markerList);
      this.map.add(this.markerList);
    }
    if(data.ItemId || data.itemId){
      this.params.CarPrices = this.itemData
    }
    if(this.$route.query.type){
      let itemData = this.SmartStorage.get("onlineData");
      let params = {
        itemId: this.$route.query.type != 'copyOrder' ? itemData.ItemId || itemData.itemId : '',
        tenantId: this.SmartStorage.get('tenant_id'),
      }
      if(itemData.extOrderId && this.$route.query.type == 'editItem'){
        params.OrderNo = itemData.extOrderId
      }
      if(this.$route.query.type != 'editItem'){
        this.isNoOrder = false
      }
      if(this.$route.query.type == 'viewOrder'){
        this.services.GetOrderDetail(params).then(async function (res) {
          if (res.success) {
            that.onlineDetail = res.content;
            let img_url = null
            if(res.content.status == 5 ){
              img_url = await that.localtionOrder(res.content)
            }
            for(let ele of that.onlineDetail.itsButtons){
              if (ele.processor == 'localtionOrder'){
                that.onlineDetail.img_url = img_url
              }
            }
            that.isCompletedOrder = true;
            that.markerSet({ slng: data.flng, slat: data.flat }, { tlng: data.tlng, tlat: data.tlat })
            that.map.setFitView(that.markerList);
            that.map.add(that.markerList);
            that.Loading = false;
            that.lodingPrice = false;
          }
        });
      }
    }
  },
  created() {
    this.setRouteListeners("priceCouponGoBack");
    this.$root.$eventHub.$on("priceCouponGoBack", (data) => {
      this.priceCouponGoBack();
      this.$root.$eventHub.$off("priceCouponGoBack"); //解绑当前监听，否则下轮监听还会持续叠加
    });
    window.fluttercallBack = this.fluttercallBack;
  },
  components: {
    cancelResonPop,
    completedOrder,
    noOrder,
    Map,
    returnBox,
    projectGrade,
    browserLoader,
    bottomContent
  },
  beforeDestroy() {
    this.map && this.map.destroy();
    this.refreshHub && this.refreshHub.uninstall();
    clearTimeout(this.timeBtn);
    this.$root.$bus.$off("reloadBtn");
    let isAndroid =
      navigator.userAgent.indexOf("Android") > -1 ||
      navigator.userAgent.indexOf("Adr") > -1; //android终端
    const container = this.$cookies.get("container");
    if (isAndroid && !["browser", "h5"].includes(container)&&!this.SmartStorage.get("isH5")) {
      this.removeOnResumeEvent();
    }
  },
  methods: {
    detailPage(){
      this.detailPageF = true
    },
    localtionOrder(item){
      return new Promise((resolve, reject) => {
        this.services.GetOrderLocation({ itemId: item.itemId}).then(function (res) {
          if (res.success) {
            if(res.code == '201'){
              resolve(false)
            }else{
              resolve(res.content)
            }
          }else{
            resolve(false)
          }
        });
        
      })
    },
    GetEstimatePrice(){
      this.itemData = this.SmartStorage.get('onlineData')
      let _this = this
      let params = this.SmartStorage.get('onlineData')
      this.services.GetEstimatePrice(params).then(function (res) {
        if (res.success) {
          _this.levelList.prices = res.content
          if(res.content){
            _this.levelList.prices.map((item) => item.isSelected = false)
          }
          _this.levelList.prices.forEach((e) => {
            e.carPrice.forEach((e2) => {
              e2.isSelected && _this.chosePrices(e2)
            })
          });
          _this.$forceUpdate();
          _this.$refs.noOrderRef && _this.$refs.noOrderRef.chosePricesO();
          _this.Loading = false;
        }
      });

    },
    saveItem(flag) {
      return new Promise((resolve, reject) => {
        if (!this.params.CarPrices) {
          this.$iToast("请选择车型！");
          this.isloding = true;
          resolve(false)
        }else{
          let params = {
            itemData: this.itemData,
            CarPrices: this.params.CarPrices,
            proposalId: this.SmartStorage.get('proposalId')
          }
          if(this.itemData.ItemId){
            params.itemId = this.itemData.ItemId
          }
          params.itemData.estimateDistance = this.estimateDistance
          this.services.OnlineSaveItem(params).then((res) => {
            if (res.success) {
              this.itemData.ItemId = res.content
              if(flag){
                resolve(res.content)
              }else{
                this.$router.push("/onlineRental")
              }
            }else{
              reject(res)
            }
          });
        }
      })
    },
    async OnlineSubmitItem(){
      let itemId = await this.saveItem(true)
      if(!itemId) return
      let params = {
        itemId: itemId,
        tenant_id: this.SmartStorage.get('tenant_id')
      }
      this.services.OnlineSubmitItem(params).then((res) => {
        if (res.success) {
          this.$router.push("/onlineRental")
        }
      });
    },
    editItem(item) {
      if(item){
        this.SmartStorage.set("editdataForm", item);
      }else{
        this.SmartStorage.set("editdataForm", this.onlineDetail);
      }
      this.$router.push("/onlineEditOrder");
    },
    refrashData(data) {
      data&&this.$router.push("/onlineRental")
    },
    h5GoClose() {
      this.showDiDiH5 = false;
      !this.isDiDiqiye&&this.$router.push("/platformIndex");
    },
    GetExtraBtns(flag) {
      let _this = this;
      let params = [this.onlineDetail.itemId];
      this.services.GetExtraBtns(params).then(function (res) {
        if (res.success) {
          let btn = {
            Txt: "满意度调查",
            Processor: "goSurvayPage",
            status: res.content[0].status,
          };
          if (res.content[0].status.status == 3) {
            _this.$iToast("订单结束14天后无法填写满意度调查");
            return;
          } else {
            _this.$refs.projectGrade.showPopup(_this.onlineDetail, btn);
          }
        }
      });
    },
    /**
     * 打开评分页面
     */
    goSurvayPage(item, btn) {
      this.GetExtraBtns();
    },
    useCarRemark(item, btn, index) {
      this.clickItem=item
      this.isCarRemarkDialog = true;
    },
    showUseCarRemark(item, btn, index) {
      this.isCarRemarkDialog = true;
      this.showBtn = false;
    },
    async SaveUseCarRemark(actDescr) {
      this.isloding = false;
      let params = {
        ItemId: this.clickItem.itemId,
        UseCarRemark: actDescr,
      };
      let _this = this;
      this.services.SaveUseCarRemark(params).then(function (res) {
        if (res.success) {
          _this.showBtn = true;
          _this.isCarRemarkDialog = false;
          _this.getOrderGetDetail();
        }
        _this.isloding = true;
      });
    },
    fluttercallBack(statu) {
      if (statu == "resumed") {
        // this.installSingler();
        this.MapLoaderM();
      }
    },
    setTimeTest() {
      this.iindex += 1;

      if (this.arr[this.iindex] && this.arr[this.iindex].lng) {
        this.arratGps.push(
          new AMap.LngLat(this.arr[this.iindex].lng, this.arr[this.iindex].lat)
        );
      }
      this.dynamicLine();
      if (this.iindex == 5) {
        this.map&&this.map.clearMap();
        this.getGetDetail();
      }
    },
    cancelOrders(data) {
      this.forceCancelOrder(data || this.params);
    },
    // 获取订单详情
    getOrderGetDetail() {
      this.lodingPrice = true;
      this.isFirst = true;
      let params = {
        proposalId:
          this.SmartStorage.get("proposalId") || this.params.proposalId,
        itemId:
          this.params.itemId || this.SmartStorage.get("orderDetail").ItemId,
      };
      this.services.GetOnlineDetail(params).then((res) => {
        if (res.success) {
          this.map && this.map.clearMap();
          this.onlineDetail = res.content;
          this.onlineDetail.itsItemData.passenger.name =
            this.StringExchangeEmoji(
              this.onlineDetail.itsItemData.passenger.name
            );
          this.onlineDetail.extOrderStatus == 100 &&
            this.onlineDetail.driverGps &&
            this.onlineDetail.driverGps.splice(
              this.onlineDetail.driverGps.findIndex((item) => item.lng == 0),
              1
            );
          // this.onlineDetail.itsButtons.filter((ele) => {
          //   if (ele.processor == 'localtionOrder') this.localtionOrder();
          // });
          this.maplineArra =
            this.onlineDetail.status == -1
              ? []
              : this.onlineDetail.extOrderStatus > 40
              ? this.onlineDetail.driverGps
              : this.onlineDetail.carBeforeArrivedGps;
          // &&
          //   this.onlineDetail.driverGps.length > 0
          // ? this.onlineDetail.driverGps
          // : this.onlineDetail.status != -1 && this.onlineDetail.status != 5
          // ? this.onlineDetail.carBeforeArrivedGps
          // : [];
          this.isFirst = false;
          // this.initmaps(this.che);
          this.MapLoaderM();
          this.Loading = false;
          this.lodingPrice = false;
          this.changStauts(res.content.status);
          let _this=this
          this.timeBtn = setTimeout(function () {
            _this.$root.$bus.$emit("reloadBtn", _this.onlineDetail);
          }, 700);
        }
      });
    },
    goLevel(data, isDiDi, isScroll) {
      this.levelList.prices.filter(function (item) {
        return (item.typeSelected = false);
      });
      if (isDiDi) {
        this.isDiDi = true;
      } else {
        this.isDiDi = false;
        data.typeSelected = true;
      }
      this.$forceUpdate();
      !isScroll &&
        document
          .getElementById(isDiDi ? "滴滴" : data.carLevel)
          .scrollIntoView();
    },
    async chosePrices(data) {
      this.params.CarPrices = data;

      // this.params.ItemData.isPickupVip =
      //   data.chosePrices.pickupServiceFee > 0 ? true : false;
    },
    async saveItemP(type) {
      this.params.proposalId = this.SmartStorage.get("proposalId");
      this.params.IsCheckRepeatOrder = this.IsCheckRepeatOrder;
      this.params.itemId = this.params.itemId
        ? this.params.itemId
        : this.params.ItemData.itemId;
      // this.request.changeAutoExtractResponseError(false);
      this.services.SaveItemOrders(this.params).then((res) => {
        if (res.success) {
          this.params.itemId = res.content;
          if (type == 1) {
            this.GetCarProtocol()
          } else {
            this.$iToast("保存成功");
            this.$router.push("/platformIndex");
          }
          res.content &&
            ["msd"].includes(this.$cookies.get("tenant")) &&
            this.SavePassenger(res.content);
        } else if (res.code == 1010) {
          this.isloding = true;
          this.epeatOrder(res, 1);
        } else {
          this.isloding = true;
          // this.$iToast(res.error || res.msg);
        }
      });
      // this.request.changeAutoExtractResponseError(false);
    },
    SavePassenger(itemId) {
      this.$store.state.passengerLixxxxxst.length > 0 &&
        this.$store.state.passengerList.forEach((element, index) => {
          if (
            element.passengers == this.params.ItemData.passenger.name &&
            element.department == this.params.ItemData.passenger_department
          ) {
            this.$store.state.passengerList.splice(index, 1);
          }
        });
      let passenger = {
        passengers: this.params.ItemData.passenger.name,
        department: this.params.ItemData.passenger_department,
      };
      this.$store.state.passengerList.push(passenger);
      this.$store.state.passengerList.forEach((element) => {
        element.passengers = this.EmojiExchangeString(element.passengers);
        element.organization =
          element.organization &&
          this.EmojiExchangeString(element.organization);
        element.department = this.EmojiExchangeString(element.department);
      });
      let params = {
        BusinessId: itemId,
        Passengers: this.$store.state.passengerList,
        IsOnline: true,
      };
      this.services.SavePassenger(params).then((res) => {
        if (res.success) {
          console.log(res);
        }
      });
    },
    priceCouponGoBack() {
      this.$router.go(-1)
    },
    comparePrice(key) {
      return function (obj1, obj2) {
        if (Number(obj1[key]) < Number(obj2[key])) {
          return -1;
        } else if (obj1[key] === obj2[key]) {
          return 0;
        } else {
          return 1;
        }
      };
    },
    MapLoaderM() {
      let that = this;
      that.map = new AMap.Map("containerP", {
        showTraffic: false, //实时路况信息
        resizeEnable: true,
        expandZoomRange: true,
      });
      if (that.onlineDetail.status != -1) {
        that.map.setFeatures(["road", "point"]);
        that.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
        });
        that.lineDriving = new AMap.Driving({
          map: that.map,
          hideMarkers: true,
          outlineColor: "balck",
          autoFitView: false,
        });
        let isAndroid =
          navigator.userAgent.indexOf("Android") > -1 ||
          navigator.userAgent.indexOf("Adr") > -1; //android终端
        const container = this.$cookies.get("container");
        if (isAndroid && !["browser", "h5"].includes(container)&&!that.SmartStorage.get("isH5")) {
          that.addOnResumeEvent();
        }
        if (that.onlineDetail.sourceCode == "EHI") {
          that.gpsconvertFrom(that.maplineArra);
        } else {
          that.initmaps(that.maplineArra);
          that.maplineArra.forEach((element) => {
            that.arratGps.push(new AMap.LngLat(element.lng, element.lat));
          });
          // this.initmaps(that.che);
          // setInterval(this.setTimeTest, 5000);
        }
      } else {
        this.addyMarck2();
        this.map.panBy(20, -100);
      }
    },
    gpsArraty(data) {
      let that = this;
      switch (data.serviceStatus) {
        case "acceptService":
        case "cancelService":
          that.getOrderGetDetail();
          break;
        case "serviceStarted":
        case "arriveService":
          that.arratGps = [];
          that.maplineArra = [];
          that.map.remove(that.polyline);
          that.map.remove(that.marker);
          that.getOrderGetDetail();
          break;
        case "serviceFinished":
        case "finishService":
          that.map&&that.map.clearMap();
          that.getOrderGetDetail();
          break;
        default:
          break;
      }
      if (data && data.driverGps && data.driverGps.lng) {
        that.arratGps.push(
          new AMap.LngLat(data.driverGps.lng, data.driverGps.lat)
        );
        that.dynamicLine();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/platform/onlinecarLeve.scss";
.messageBoxSvg{
  display: none;
}
.messageTxt{
  padding: .3rem;
}
// .didiMask{
//   position: fixed;
//     width: 100%;
//     top: 0.39rem;
//     height: 69%;
//     background: rgba(0,0,0,.3);
//     z-index: 9;
//     text-align: center;
//     line-height: 6rem;
//     color: #fff;
// }
.submit_box{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: .9rem;
  background: #FAFAFA;
  padding: 0.1rem 0.2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #F5F5F5;
  .submit_time{
    font-size: .10rem;
  }
.submit_btn_box{
  .submit_btn{
    padding: 0.1rem 0.14rem;
    background: var(--themeColor);
    color: #fff;
    width: 40%;
    float: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0.04rem;
    font-size: .16rem;
    font-weight: 700;
    margin-top: 0.05rem;
  }
}
  
}
</style>