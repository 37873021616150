var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"juheBox"},[_c('ul',[_vm._l((_vm.levelList),function(lItem,lindex){return _c('div',{key:lindex,staticClass:"carItems",attrs:{"id":lItem.carLevel}},[(lItem.carPrice.length > 0)?[_c('div',{staticClass:"levelCount"},[_c('p',[_vm._v(" "+_vm._s(lItem.carLevelTxt)),_c('i',[_vm._v(_vm._s(lItem.carPrice.length))])])]),_vm._l((lItem.carPrice),function(jitem,jindex){return _c('li',{key:jindex},[_c('div',[_c('p',[_vm._v(_vm._s(jitem.supplierName)+" - "+_vm._s(jitem.typeTxt))]),_c('span',[_vm._v(" 隐私保护，敢做敢赔")])]),_c('p',{staticClass:"priceCon"},[_c('img',{staticStyle:{"margin":"0","width":".2rem","height":".2rem"},attrs:{"src":require("../../../../assets/images/details_img.png"),"alt":""},on:{"click":function($event){return _vm.opendetial(jitem)}}}),_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.choiseSpu(jitem)}}},[_vm._v("预估"+_vm._s(jitem.price)+"元")]),_c('svg',{class:[
                  'icon',
                  'checkIcon',
                  jitem.isSelected && 'noCheckIcon',
                  (jitem.isSelected) && 'onCheckIcon',
                  !jitem.isCanSelect && 'backgroundCcc'
                ],attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.choiseSpu(jitem)}}},[_c('use',{attrs:{"xlink:href":jitem.isSelected
                      ? '#iconcheckboxok'
                      : '#iconcheckboxno'}})])])])})]:_vm._e()],2)}),(!_vm.levelList)?_c('div',{staticClass:"nodataDiv"},[_c('p',{staticClass:"noinfor"},[_vm._v("暂无符合的运力报价，请稍后再试")])]):_vm._e()],2)]),(_vm.detailPageF)?_c('priceDetail',{attrs:{"data":_vm.detailData},on:{"close":_vm.closepriceDetail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }