var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      'bottomBox',
      (_vm.isReceivedOrder || [-1, 0].includes(_vm.onlineDetail.status)) &&
        'lowHieghtBottom',
      _vm.isCompletedOrder &&
        ![-1, 0].includes(_vm.onlineDetail.status) &&
        'HieghtBottom',
      _vm.otherBtns.length > 0 && 'flowTop' ]},[(_vm.isCompletedOrder || _vm.isReceivedOrder)?_c('div',[(_vm.isCompletedOrder && ![-1, 0].includes(_vm.onlineDetail.status))?_c('div',{staticClass:"driverbox"},[_c('img',{attrs:{"src":require("../../../../assets/images/driverHead.png")}}),_c('span',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverCarType))]),_c('span',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverName))]),_c('span',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverCode))]),_c('span',[_vm._v(_vm._s(_vm.onlineDetail.extOrderDataDynamicData.driverColor))])]):_vm._e(),_c('ul',{staticClass:"bottomBtnList"},[(_vm.isDrop)?_c('div',{staticClass:"dropDownBox"},[((!_vm.onlineDetail.extOrderDataDynamicData.driverMobile && _vm.processBtn.length > 4) || (_vm.onlineDetail.extOrderDataDynamicData.driverMobile && _vm.processBtn.length > 3))?_vm._l((_vm.processBtn.slice(
                _vm.onlineDetail.extOrderDataDynamicData.driverMobile?2:3,
                _vm.processBtn.length
              )),function(btn1,bindex1){return _c('span',{key:bindex1,on:{"click":function($event){$event.stopPropagation();_vm.isloding && _vm.btnClick(_vm.onlineDetail, btn1)}}},[_vm._v(_vm._s(btn1.txt))])}):_vm._e()],2):_vm._e(),((!_vm.onlineDetail.extOrderDataDynamicData.driverMobile && _vm.processBtn.length > 4) || (_vm.onlineDetail.extOrderDataDynamicData.driverMobile && _vm.processBtn.length > 3))?_c('a',{staticClass:"moreBtn",on:{"click":function($event){$event.stopPropagation();_vm.isDrop = !_vm.isDrop}}},[_vm._v("更多")]):_vm._e(),(_vm.onlineDetail.extOrderDataDynamicData.driverMobile)?_c('li',{on:{"click":function($event){return _vm.ihtml.telCall(_vm.onlineDetail.extOrderDataDynamicData.driverMobile)}}},[_c('svg',{staticClass:"icon bottomIcon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icondianhua-"}})]),_c('p',[_vm._v("联系司机")])]):_vm._e(),_vm._l((_vm.processBtn.slice(0, _vm.onlineDetail.extOrderDataDynamicData.driverMobile && _vm.processBtn.length > 3? 2 : !_vm.onlineDetail.extOrderDataDynamicData.driverMobile && _vm.processBtn.length > 4 ? 3 : 4)),function(btn,bindex){return _c('li',{key:bindex,class:btn.processor,on:{"click":function($event){btn.processor != 'localtionOrder' && _vm.isloding && _vm.btnClick(_vm.onlineDetail, btn) ||
            btn.processor == 'localtionOrder' && _vm.isloding && !_vm.onlineDetail.img_url && _vm.localtionOrder(false)}}},[_c('svg',{staticClass:"icon bottomIcon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + btn.css}})]),_c('p',[_vm._v(_vm._s(btn.txt))]),((btn.processor == 'localtionOrder' && _vm.onlineDetail.img_url))?_c('viewer',{staticClass:"imgContainer",attrs:{"images":[_vm.onlineDetail.img_url]}},_vm._l((_vm.onlineDetail.img_url),function(item){return _c('img',{key:item.url,staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.url,"alt":""}})}),0):_vm._e()],1)})],2)]):[(!_vm.isPlaceOrder && _vm.itemData)?_c('div',{staticClass:"timeBox"},[_c('svg',{staticClass:"icon timeIcon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-shijian"}})]),_vm._v(_vm._s(_vm.itemData.carRule == 14 ? "立即出发" : _vm.itemData.departure_time + "出发")+" ")]):_vm._e(),_c('div',{staticClass:"btnBox"},[_c('div',{staticClass:"infoBox"},[_c('p',[_vm._v(" 预估"),_c('i',[_vm._v(_vm._s(_vm.chosePricesInfo.minPrice || 0)+"~"+_vm._s(_vm.chosePricesInfo.maxPrice || 0))]),_vm._v("元起 ")]),_c('span',[_vm._v("共"),_c('i',[_vm._v(_vm._s(_vm.nuPriceArr.length || 0))]),_vm._v("个车型")])]),_c('div',{class:[
            'bottomBtn',
            (!_vm.isloding || _vm.priceArr.length == 0) && 'unclickSubmit' ],on:{"click":function($event){$event.stopPropagation();_vm.isloding && _vm.saveItem(1)}}},[(!_vm.isloding)?_c('svg',{staticClass:"icon circleIcon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconshuaxin"}})]):_vm._e(),_vm._v(_vm._s(!_vm.isPlaceOrder ? "同时呼叫" : "追加叫车")+" ")])])]],2),(_vm.otherBtns.length > 0)?_c('div',{staticClass:"otherBtnbox"},_vm._l((_vm.otherBtns),function(obtn,oindex){return _c('div',{key:oindex,on:{"click":function($event){_vm.isloding && _vm.btnClick(_vm.onlineDetail, obtn)}}},[_c('h3',[_vm._v(_vm._s(obtn.title))]),_c('p',[_vm._v(_vm._s(obtn.subTitle))]),_c('div',{staticClass:"otherTogo"},[_vm._v(" "+_vm._s(obtn.txt)+" "),_c('svg',{staticClass:"icon otherBtnIcon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconarrow-right"}})])])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }