<template>
  <div>
    <div class="juheBox">
      <ul>
        <div
          class="carItems"
          :id="lItem.carLevel"
          v-for="(lItem, lindex) in levelList"
          :key="lindex"
        >
          <template v-if="lItem.carPrice.length > 0">
            <div class="levelCount">
              <p>
                {{ lItem.carLevelTxt }}<i>{{ lItem.carPrice.length }}</i>
              </p>
            </div>
            <li
              v-for="(jitem, jindex) in lItem.carPrice"
              :key="jindex"
            >
              <div>
                <p>{{ jitem.supplierName}} - {{ jitem.typeTxt}}</p>
                <span> 隐私保护，敢做敢赔</span>
              </div>
              <p class="priceCon">
                <img @click="opendetial(jitem)" style="margin:0;width:.2rem;height: .2rem" src="../../../../assets/images/details_img.png" alt="">
                <span @click.stop="choiseSpu(jitem)">预估{{ jitem.price }}元</span>
                <svg
                  @click.stop="choiseSpu(jitem)"
                  :class="[
                    'icon',
                    'checkIcon',
                    jitem.isSelected && 'noCheckIcon',
                    (jitem.isSelected) && 'onCheckIcon',
                    !jitem.isCanSelect && 'backgroundCcc'
                  ]"
                  aria-hidden="true"
                >
                  <use
                    :xlink:href="
                      jitem.isSelected
                        ? '#iconcheckboxok'
                        : '#iconcheckboxno'
                    "
                  />
                </svg>
              </p>
            </li>
          </template>
        </div>
        <div class="nodataDiv" v-if="!levelList">
          <p class="noinfor">暂无符合的运力报价，请稍后再试</p>
        </div>
      </ul>
    </div>
    <priceDetail v-if="detailPageF" :data="detailData" @close="closepriceDetail"/>              

  </div>
</template>

<script>
import priceDetail from '@/views/common/platform/priceCoupon/priceDetail.vue'
export default {
  components: {
      priceDetail
  },
  props: [
    "levelList",
    "didiPriceList",
    "isPlaceOrder",
    "itemData",
    "saveItem",
    "params",
    "cancelOrders",
    'detailPageF'
  ],
  data() {
    return {
      chosePricesInfoN: {},
      chosePricesSection: {},
      timeerp: {},
      detailData: {},
      showpriceDetailSelect: true
    };
  },
  mounted(){
    this.chosePricesO();
  },
  beforeDestroy() {
    clearTimeout(this.timeerp);
  },
  methods: {
    closepriceDetail(){
      this.showpriceDetailSelect = false
    },
    opendetial(e){
    this.detailData = e
    console.log('00000--',this.detailData)
    this.$emit('detailPage', true)
    },
    headError(e) {
      e.target.src = require("../../../../assets/images/defaultSup.png");
    },
    choiseSpu(item) {
        if(!item.isCanSelect){
          return
        }
        this.levelList.forEach((e) => {
          e.carPrice.forEach((e2) => {
            e2 !== item ? e2.isSelected = false : item.isSelected = !item.isSelected
          })
        });
        if(item.isSelected){
          this.$emit("chosePrices", item);
        }
        item.isSelected ? this.$emit("chosePrices", item) : this.$emit("chosePrices", false)
        this.$forceUpdate();
    }, 
    chosePricesO(type) {
      this.chosePricesSection = this.levelList
    },
  },
};
</script>

<style>
.backgroundCcc{
  background-color: #ccc;
  border-radius: 8px;
}
</style>