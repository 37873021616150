<template>
    <div class="completedOrderBox">
        <div class="statusbox">
            {{ onlineDetail.status == 0 ? "您还" : "" }}{{ onlineDetail.extOrderStatusTxt }}
            <svg class="icon statuIcon" aria-hidden="true">
                <use xlink:href="#iconshenqingtuikuanchenggong"></use>
            </svg>
            <span class="tags">企业支付费用</span>
        </div>
        <div class="briefBox" v-if="onlineDetail.status == 5">
            <img :src="onlineDetail.extOrderDataDynamicData.sourceIconUrl" />
            {{ onlineDetail.extOrderDataDynamicData.sourceName }}提供本次服务 全程{{ onlineDetail.extOrderDataDynamicData.driverNormalDistance }}公里 时长{{ onlineDetail.extOrderDataDynamicData.driverNormalTime }}分
        </div>
        <div class="briefBox" v-if="onlineDetail.status == -1">
            {{ onlineDetail.extOrderstatus == 24 ? "订单强制取消，存在取消费用" : "订单已取消，请重新下单" }}
        </div>
        <div class="priceBox">
            <div class="priceNum">{{ onlineDetail.status == "-1" ? onlineDetail.settleAmount : (onlineDetail.status == "5" ? onlineDetail.settleAmount : onlineDetail.amount) || 0 | currencyFormatter }}<span>元</span></div>
            <div class="priceRight" @click="showDetail">
                展开详情<svg class="icon shortIcon" aria-hidden="true">
                    <use :xlink:href="isShowDetailPrice?'#iconarrow-top':'#iconarrow-bottom'" />
                </svg>
            </div>
        </div>
        <div class="priceDetail" v-show="isShowDetailPrice">
            <template v-if="onlineDetail.priceDetails && onlineDetail.priceDetails.length > 0">
                <p v-for="(price, pindex) in onlineDetail.priceDetails" :key="pindex">
                    {{ price.name }}
                    <span> {{ price.amount }}元</span>
                </p>
            </template>

            <div class="statuBord">
                第三方订单状态
                <span>{{ onlineDetail.extOrderStatusTxt || "暂无" }}</span>
            </div>
            <p>
                预计出发地
                <span>{{ onlineDetail.itsItemData.puAddress }}</span>
            </p>
            <p>
                预计目的地
                <span>{{ onlineDetail.itsItemData.destAddress }}</span>
            </p>
            <p>
                实际出发地
                <span>{{ (onlineDetail.extOrderDataDynamicData && onlineDetail.extOrderDataDynamicData.realStartName) ?  onlineDetail.extOrderDataDynamicData.realStartName : "暂无" }}</span>
            </p>
            <p>
                实际目的地
                <span>{{  (onlineDetail.extOrderDataDynamicData && onlineDetail.extOrderDataDynamicData.realEndName) ? onlineDetail.extOrderDataDynamicData.realEndName : "暂无" }}</span>
            </p>

  <p v-if="onlineDetail.itsItemData.orderTime">
        下单时间
        <span>{{ onlineDetail.itsItemData.orderTime }}</span>
      </p>
      <p v-if="onlineDetail.status == -1">
        取消方式<span>{{
          onlineDetail.itsItemData.isActiveCancel ? "用户取消" : "平台取消"
        }}</span>
      </p>
      <p v-if="onlineDetail.itsItemData.waitTime">
        等待时间
        <span
          ><template v-if="onlineDetail.itsItemData.waitTime > 60">
            {{ (onlineDetail.itsItemData.waitTime / 60).toFixed(2) }}min
          </template>
          <template v-else>
            {{ onlineDetail.itsItemData.waitTime }}s
          </template></span
        >
      </p>
      <template v-if="onlineDetail.status == 5">
        <p>
          上车时间
          <span>{{ onlineDetail.extOrderDataDynamicData.startTime || "暂无" }}</span>
        </p>
        <p>
          完成时间
          <span>{{ onlineDetail.extOrderDataDynamicData.endTime || "暂无" }}</span>
        </p>
      </template>

        </div>
    </div>
</template>

<script>
export default {
    props: ["onlineDetail", "hasOtherBtn"],
    data() {
        return {
            isShowDetailPrice: false,
        };
    },
    mounted(){
        let pricescrollBox = document.getElementsByClassName("priceCouponContent")[0];
        if(this.onlineDetail.status == 1){
            pricescrollBox.style.height = '220px'
        }
    },
    methods: {
        showDetail() {
            this.isShowDetailPrice = !this.isShowDetailPrice;
            let priceCouponBox = document.getElementsByClassName("priceCouponBox")[0];
            let pricescrollBox = document.getElementsByClassName("priceCouponContent")[0];
            if (this.isShowDetailPrice) {
                priceCouponBox.style.height = this.onlineDetail.status == 1 ? "4.4rem" : "4.8rem";
                pricescrollBox.style.height = this.onlineDetail.status == 1 ? "320px" : "360px";
            } else {
                priceCouponBox.style.height = this.onlineDetail.status == 1 ? "2rem" : "2.5rem";
                pricescrollBox.style.height = this.onlineDetail.status == 1 ? "220px" : "260px";
            }
        },
    },
};
</script>
