<template>
  <div class="detail_page">
    <!-- <iHeader
      backType="blank"
      :headStyle="{
        background: 'var(--themeColor)',
        color: '#fff'
      }"
      :backEvent="true"
      @back-event="priceDetailGoBack()"
      :back="true"
    >
      <div slot="headText">
        <span class="head-txt">
          预估车费明细
        </span>
      </div>
    </iHeader> -->
    <div class="detail_page_content">
      <p class="detail_page_title fontW">{{ data.supplierName + '-' + data.carLevelTxt }}</p>
      <div class="detail_top">预估费用明细</div>
      <div class="detail_item">
        <div class="detail_price">
          <div>基础价格({{ data.expenseInfo.km }}公里)</div>
          <div>{{ data.expenseInfo.basicPrice | keep2digit }}元</div>
        </div>
        <div class="detail_info" v-if="data.expenseInfo.limitKm && data.expenseInfo.limitTime">公里限制{{ data.expenseInfo.limitKm }}公里，时长限制{{ data.expenseInfo.limitTime }}小时</div>
      </div>
      <div class="detail_item" v-if="data.expenseInfo.overkmCost">
        <div class="detail_price">
          <div>超公里费用</div>
          <div>{{ data.expenseInfo.overkmCost | keep2digit }}元</div>
        </div>
        <div class="detail_info">超每公里价格{{ data.expenseInfo.overKm }}元</div>
      </div>
      <div class="detail_item" v-if="data.expenseInfo.overtimeCost">
        <div class="detail_price">
          <div>超时费</div>
          <div>{{ data.expenseInfo.overtimeCost | keep2digit }}元</div>
        </div>
        <div class="detail_info">超每小时价格{{ data.expenseInfo.overTime }}元</div>
      </div>
      <div class="detail_item" v-if="data.expenseInfo.longwayCost">
        <div class="detail_price">
          <div>远途费</div>
          <div>{{ data.expenseInfo.longwayCost | keep2digit }}元</div>
        </div>
        <div class="detail_info">每公里价格2元</div>
      </div>
      <div class="detail_item" v-if="data.expenseInfo.returnCost">
        <div class="detail_price">
          <div>空返费</div>
          <div>{{ data.expenseInfo.returnCost | keep2digit }}元</div>
        </div>
        <div class="detail_info">每公里价格2元</div>
      </div>
      <div class="detail_item" v-if="data.expenseInfo.roomCost">
        <div class="detail_price">
          <div>食宿费</div>
          <div>{{ data.expenseInfo.roomCost | keep2digit }}元</div>
        </div>
        <div class="detail_info">250元/间，过夜需增加1顿早餐，1日3餐。</div>
      </div>
    </div>
    <div class="detail_page_bottom">
      <div>
        预估支付费用
        <span class="estimated_cost">{{ data.price | keep2digit }}</span>
        元
      </div>
      <div class="tips_color">*以上预估金额由易来回提供</div>
    </div>
    <div class="detail_page_tips">
      预估价根据实时交通状况、预计行驶里程、时间等因素估算得出，存在波动，仅供参考。远途费、空返费、食宿费、出差津贴、路桥费、餐饮费、停车费及其他附加费用可能不包含在预估价中，最终以实际支付金额为准。
    </div>
  </div>
</template>

<script>
export default {
  name: 'priceDetail',
  props: ['data'],
  data() {
    return {};
  },
  filters: {
    keep2digit(value) {
      return value.toFixed(2);
    }
  },
  created() {
    this.setRouteListeners('priceDetailGoBack');
    this.$root.$eventHub.$on('priceDetailGoBack', data => {
      this.priceDetailGoBack();
      this.$root.$eventHub.$off('priceDetailGoBack'); //解绑当前监听，否则下轮监听还会持续叠加
    });
    window.fluttercallBack = this.fluttercallBack;
  },
  methods: {
    priceDetailGoBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.detail_page {
  width: 100vw;
//   height: calc(100vh-.6rem);
  background: #ffffff;
  position: fixed;
  z-index: 9991;
  top: .4rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
}
.detail_page_content {
  padding: 0.18rem;
  border-bottom: 1px solid #f5f5f5;

  .detail_page_title {
    font-size: 0.24rem;
    margin: 0.3rem 0 0.3rem 0;
    text-align: left;
    font-weight: 500;
  }
  .detail_top {
    text-align: left;
    font-size: 0.16rem;
    font-weight: 500;
  }
  .detail_item {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    margin-top: 0.2rem;
    .detail_price {
      display: flex;
      justify-content: space-between;
      color: #13161b;
      font-size: 0.15rem;
      font-weight: 500;
    }
    .detail_info {
      text-align: left;
      color: #909399;
      font-size: 0.12rem;
    }
  }

  .item_title {
    text-align: left;
    padding: 0.3rem 0 0.3rem 0;
  }
}
.detail_page_bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.18rem;
  color: #13161b;
  .estimated_cost {
    font-size: 0.26rem;
    margin: 0 0.1rem;
    font-weight: 500;
  }
  .tips_color {
    color: #fc3258;
    font-size: 0.12rem;
  }
}
.detail_page_tips {
  padding: 0.18rem;
  color: #90939a;
  font-size: 0.12rem;
  // display: flex;
  text-align: left;
}
</style>
